import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import moment from "moment";
import {BrowserRouter} from "react-router-dom";
import Delayed from "./components/Hidden";

moment.locale("de")

const App = React.lazy(() => import("./App"))

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <React.Suspense fallback={
          // @ts-ignore
          <Delayed delay={100}>
              <h1>Skripte</h1>
          </Delayed>
      }>
          <BrowserRouter>
              <App/>
          </BrowserRouter>
      </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
